//-----------PHONE & TABLET-------------------

@media screen and (max-width: 1644px) {
    
    #contact form {
        .col-xs-6 { width: 100% }
        .col-xs-6:first-child { padding-right: 0 }
        .col-xs-6:last-child { padding-left: 0; margin-top: 30px }
    }
    
    #main-nav ul { width: 95% }
    .en-tete { width: 50% }
    #cgu .en-tete, #legal .en-tete { width: auto }
    #onparledenous .slider .contain { width: 60%; margin: 0 }
    
}

@media screen and (max-width: 1275px) {
    
    #main-nav ul #logo a { margin: 0 }
    
    #services .row .col-xs-2, #produits .row .col-xs-2 { width: 20% }
    #onparledenous .slider .contain { width: 70%; margin: 0 }
    
    #produits {
        height: auto;
        .home-prod-wrap, .filters {
            flex-wrap: wrap;
            width: 90%;
            margin: 90px auto;
            article { float: none; clear: both; width: 30%; margin: 50px 30px }
        }
        .filters { margin-top: 0; margin-bottom: -10px }
        .row:last-child { margin-bottom: 90px }
    }
    
    #services {
        height: auto;
        .row:last-child {
            flex-wrap: wrap;
            width: 90%;
            margin: 90px auto;
            article { float: none; clear: both; width: 30%; margin: 50px 30px }
        }
    }
    
    #panier .col-md-7 { width: 80% }

    #contact .col-md-4 { width: 435px }

    #events .invit .col-md-7 { width: 85% }
    #events .menu { width: 85% }
    
}

@media screen and (max-width: 1024px) {
    
    .tarif-tab { width: 65% }
    .tarifs > .row:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        .col-md-3 { width: 70% }
    }
    
    #onparledenous {
        height: 675px;
        
        .slider {
            .fa { margin-top: 100px }
            .contain {
                width: 60%;
                margin-top: -50px;
                
                div { flex-wrap: wrap }
                img { margin: 0 20px 100px 20px }
            }
        }
    }

    #histo-cmd, #account, #user-carteCadeau, #reservations, #events, #event100ans {
        .row .menu {
            flex-direction: column;
            hr { transform: rotate(180deg); margin: 30px 0 }
        }
    }

    #events .invit .col-md-7 {
        width: 920px;
        display: flex;
        flex-direction: column;
        align-items: center;

        article { width: 70%; height: 420px }
    }
    
    footer .row .col-xs-2 { width: 25% }
    
}

@media screen and (max-width: 992px) {

    #event100ans-root {
        #form {
            padding:30px;
            input {
                width:100%;
            }
        }
    }
    
    //#presentation img { float: none; clear: both }
    #produits .filters {
        justify-content: center;
        select {  margin-bottom: 30px }
        #shop-search { position: static }
    }
    
    header { height: 700px !important; }
    header > .row {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    
    #main-nav {
        height: auto;
        background-image: none;
        ul {
            padding: 0;
            li { display: none }
        }
    }
    
    #logo { display: block !important }
    
    .mob-ui {
        display: block;
        color: white;
        position: fixed;
        top: 15px;
        left: 10px;
        z-index: 200;
        
        #mob-nav {
            position: fixed;
            width: 280px;
            height: 100vh;
            border-right: 2px solid #ad6d49;
            background-color: #1b1716;
            display: flex;
            justify-content: center;
            top: 0;
            left: -446px;
            width: 40vw;
            min-width: 280px;
            transition: left .3s;
            z-index: 100;
            
            #close {
                position: absolute;
                top: 30px;
                left: 30px;
            }
            
            ul {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                list-style: none;
                height: 375px;
                margin-top: 125px;
                padding: 0;
                
                li a {
                    color: white;
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 0.9px;
                }
            }
        }
    } 
    .mob-panier { top: 80px;
        .fa-shopping-bag { margin-top: -5px }
    }
    #menu-burger, .mob-panier {
        background-color: black;
        width: 50px;
        height: 50px;
        border-radius: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #ad6d49;
    }
    #menu-burger .fa-bars { font-size: 24px }
    
    #presentation article h1 { font-size: 40px }
    
    .en-tete { width: 90% }
    
    .horaires { 
        article { width: 100% }
    }
    
    #pres-prod article {
        flex-direction: column;
        align-items: center;
        .col-md-4.desc { padding-left: 0 }
        .col-md-4:first-child { padding-left: 0; margin-bottom: 20px; margin-right: 0 }
    }
    #pres-prod .ariane { margin-left: 35px; margin-right: 0 }
    #pres-prod .popup-body, #commande .popup-body { width: 85% !important }
    
    #panier-plein table {
        width: 100%;
        
        tr {
            display: flex !important;
            justify-content: space-between;
            flex-wrap: wrap;
            
            td.col-xs-8 { width: 100%; padding-top: 30px; padding-bottom: 30px }
            td.col-xs-2 { height: auto !important; margin-bottom: 30px; width: 85px }
            .fa-times { top: -210px !important }
            .col-xs-2:last-child { padding: 0 }
        }
    }
    
    #commande #cb .modif { position: static !important }
    #commande #submit { width: 100% !important }
    
    #account .col-md-4 { float: none; clear: both; margin-right: 0 !important }
    #account article.col-md-4 { margin-bottom: 40px !important }
    #account .infos-perso { flex-direction: column; align-items: center }

    #reservations .col-md-8:not(.menu) { width: 80% }

    .preced-next { margin-top: 25px; margin-bottom: 15px }
    
    #contact {
        height: auto;
        flex-direction: column;
        align-items: center;
        padding-bottom: 75px;

        .colonne {
            width: 80vw !important;
            max-width: 80vw !important;
            min-width: 80vw !important;
        }

        .info-cols {
            display: flex;
            flex-direction: column;

            #mapContact {
                margin-top: 0;
            }

            .col {
                width: 100% !important;
                max-width: 100% !important;
                min-width: 100% !important;
            }
        }
    }
    
    footer p {
        width: 80%;
        text-align: center;
    }

    .widgetResa {
        iframe {
            height: 1600px !important;
        }
    }

}

@media screen and (max-width: 775px) {

    #event100ans-root {
        .row {
            margin-top:15px;
        }
        #form {
            padding:30px;
            input {
                width:100%;
            }
        }
    }
    
    #pres-prod article .col-md-4.desc {
        width: 70%;
        padding: 0;
        margin-top: 0;
        
        .bouton { margin: auto }
    }
    
    #histo-cmd .row .col-md-8 { width: 90% }
    #user-carteCadeau .row .col-md-8 { width: 90% }
    
    #contact-us #popup-ajout .popup-body { width: 90% }

    #events .invit .col-md-7 article { width: 520px; height: 345px }
    
}

@media screen and (max-width: 660px) {
    
    #event100ans-root {
        .row {
            margin-top:15px;
        }
        #form {
            padding:30px;
            input {
                width:100%;
            }
        }
    }
    

    #onparledenous {
        height: 1075px;
        
        .slider {
            .fa { margin-top: 575px }
            .fa-angle-left { margin-right: -100px }
            .fa-angle-right { margin-left: -100px }
            .contain {
                width: 40%;
                margin-top: -575px;
                
                div { flex-wrap: wrap }
                a { margin: 0 20px 100px 20px }
            }
        }
    }
    
    #liste-produits { width: 90% !important }
    #prix-select { margin: 0 30% !important; margin-bottom: 30px !important }
    
    #histo-cmd, #user-carteCadeau {
        .row .col-md-8:not(.menu) article {
            .col-xs-11, .col-xs-12 {
                flex-direction: column;
                p { margin-top: 25px; margin-right: 0; padding-right: 0; text-align: center }
                .prix { margin: 0; margin-top: 15px }
                a { margin-right: 0 }
            }
            .bottom-line {
                flex-direction: column;
                p { margin-right: 0; margin-bottom: 25px }
            }
        }
    }

    #reservations .row .col-md-8:not(.menu) article {
        .col-xs-11 {
            flex-direction: column;
            align-items: start;
            .prix { margin: 0; margin-top: 5px }
        }
        .bottom-line {
            flex-direction: column;
            p { margin-right: 0; margin-bottom: 25px }
        }
        .modif-annul .modif { margin-right: 15px !important }
    }
    
    #panier-plein table td.col-xs-2 { padding: 0 }
    #total {
        flex-direction: column;
        h2 { margin-right: 0 !important }
        .bouton { margin-top: 40px }
    }

    #contact-us .row .col-md-7 {
        width: 85%;
        form > .row:first-child {
            display: flex;
            flex-direction: column;
            .col-xs-6 { padding: 0; width: 100% }
        }
        form > .row:last-child {
            display: flex;
            .bouton { width: 100% }
        }
    }

    #contact .col-md-4 { margin-left: 0 }
    
    footer .row:first-child {
        width: 250px;
        margin: auto;
        .col-xs-2 {
            width : 250px;
            ul { padding-bottom: 30px; border: none; border-bottom: 1px solid #4a4646; }
        }
    }
    
}

@media screen and (max-width: 550px) {

    #presentation {
        .pres-imgs {
            height: auto;
            min-height: auto;
            img.pres { width: 100% !important }
        }
        article { margin-top: 30px !important }
    }
    
    #panier-plein table tr {
        td.col-xs-8 {
            height: auto !important;
            div { float: none !important; clear: both; margin-right: auto !important;  margin-left: auto }
        }
        
        td.col-xs-2 .fa-times { top: -350px !important }
    }
    
    #login article.col-md-3 { width: 80% !important }
    
    #account .col-md-4 { width: 80% !important;
        .col-xs-12 { width: 100% !important }
    }
    
    #account #popup-ajout .popup-body { width: 90% }

    #events .invit .col-md-7 article { width: 315px; height: 200px }
    
}

@media screen and (max-width: 458px) {
    
    #categorie-select { margin-left: 0 !important }
    #prix-select { margin: 0 15px 30px 0 !important }
    #produits .filters select { width: 300px; margin-right: 0 !important }
    #pres-prod article .col-md-4:first-child img { width: 300px; height: 300px }
    #commande .frais { position: static !important; margin-left: 62px }
    #commande .col-md-8 { width: 80% }
    #commande #recap .prix { position: static !important }
    #contact .col-md-4 {
        width: 320px;
        .fa-facebook { margin-top: 15px }
        .fa-instagram { margin-top: 20px }
    }
    #privatiser .date { margin-right: 0; padding: 0 20px }
    #rgpd {
        flex-direction: column;
        .bouton  { margin-left: 0; margin-top: 10px }
        p { text-align: center }
    }
    .preced-next { justify-content: space-between; padding-left: 25px }
    #contact, footer .row:first-child { display: none }
}