#header-bg-mob { display: none }

#header-bg {
    position: absolute;
    bottom: -168px;
    width: 100%;
    height: 150%;
}

header {
    height: 910px;
    position: relative; 
    background-color: #1b1716;
    overflow: hidden;
    .row { z-index: 5; position: relative; display: flex; flex-direction: column; }
    video { object-fit: fill }
}

#play-icon:not(.disabled) { align-self: center; margin-top: 100px; opacity: 1; transition: opacity .3s; cursor: url(/images/Cursor_1.png) 5 0, pointer }

#presentation article { margin-top: 35px; width: 750px }

#services {
    height: 952px;
    background-image: url(/images/bg-services.png);
    
    .row {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        margin-left: 0;
        
        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            p {
                text-align: center;
                line-height: 24px;
            }
            
            .underline {
                height: 2px;
                width: 45px;
                background-color: #ad6d49;
                margin-top: 5px;
            }
        }
        
        .col-xs-2 {
            height: 470px;
            border: 2px solid #ad6d49;
            background-color: #1b1716;
            margin-top: -15px;
            width: 15%;
            min-width: 270px;
            margin-right: 30px;
            
            p {
                font-size: 13px;
                line-height: 22px;
                letter-spacing: 0.28px;
                margin: 40px 20px 0px 20px;
                min-height: 135px;
            }
            
            img {
                margin: 45px 0 25px 0;
                height: 78px;
            }
            
            .bouton {
                background-color: transparent;
                border: 2px solid white;
                border-radius: 18px;
                padding: 8px 30px;
                letter-spacing: 0.9px;
                font-size: 13px;
                color: white !important;
                margin-top: 10px;
            }
            
            .bouton:hover {
                background-color: white;
                color: black !important;
                cursor: url(/images/Cursor_1.png) 5 0, pointer;
            }
        }
    }
}

#onparledenous {
    height: 550px;
    background-color: #ad6d49;
    
    .row {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        
        .square { background-color: #1b1716 }
        
        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            p {
                text-align: center;
                line-height: 24px;
            }
        }
    }
    
    .slider {
        margin-top: 100px;
        align-items: center;
        justify-content: space-evenly;
        
        .fa { color: #1b1716; font-size: 50px; }
        .fa:hover { color: white; cursor: url(/images/Cursor_1.png) 5 0, pointer; }
        
        .contain {
            width: 50%;
            margin: 0 -100px;
            position: relative;
            height: 75px;
            
            div {
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: absolute;
                top: 0;
                width: 100%;
                opacity: 0;
                transition: opacity .3s;
            }
            div:first-child { opacity: 1 }
        }
    }
}

header a[title="Accueil"] { color: #ad6d49 !important }
.home-prod-wrap { margin-top: 90px !important }

@import "../mobile.scss";

@media screen and (max-width: 885px) {
    .mob-ui #mob-nav ul li a[title="Accueil"] { color: #ad6d49 !important }
}

@media screen and (max-width: 992px) {

    header .row {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.80);
        #main-nav ul { padding-top: 31px; }
    }

    #header-bg-mob {
        display: block !important;
        position: absolute;
        height: 624px;
        bottom: 0;
        width: 1920px;
    }
    
    #header-bg { display: none }
    #play-icon { display: none }

}

@media screen and (max-width: 1600px) {
    #presentation .pres-imgs { width: 45% }
}

@media screen and (max-width: 1440px) {
    #presentation .pres-imgs { width: 40% }
}

@media screen and (max-width: 1300px) {
    #presentation {
        height: auto;
        .pres-imgs { width: 100% }
        img.pres { width: 100% }
        article { width: auto; margin-top: 60px; padding-right: 50px; margin-bottom: 80px }
    }
}